<template lang="html">
  <div style="min-height:100vh">
    <v-navigation-drawer
      clipped
      fixed
      app
      permanent
      width="300"
      :mini-variant.sync="isMiniVariant"
      :expand-on-hover="($vuetify.breakpoint.mdAndDown || $vuetify.breakpoint.width<$vuetify.breakpoint.height)"
      class="d-print-none"
    >
      <template v-slot:prepend>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-img :src="`${publicPath}assets/logo-with-text.svg`" max-width="300" v-if="!isMiniVariant"></v-img>
              <v-img :src="`${publicPath}assets/logo-small.png`" max-width="60" v-else></v-img>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <v-divider></v-divider>
      <MainMenu></MainMenu>
      <template v-slot:append v-if="!isMiniVariant">
        <v-list nav>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-if="$store.getters.getCurrentUser" class="text-truncate"><v-icon>person</v-icon> {{ $store.getters.getCurrentUser.name }}</v-list-item-title>
              <v-btn block @click="logout"><v-icon>mdi-logout</v-icon>Log out</v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main style="min-height:100vh">
      <v-container fluid>
        <v-row no-gutters>
          <v-alert-panel class="d-print-none"></v-alert-panel>
          <v-col>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>


    <v-snackbar-queue top location="top" class="d-print-none"></v-snackbar-queue>
    <v-snackbar-queue top right location="top-right" class="d-print-none"></v-snackbar-queue>
    <v-snackbar-queue top left location="top-left" class="d-print-none"></v-snackbar-queue>
    <v-snackbar-queue bottom right location="bottom-right" class="d-print-none"></v-snackbar-queue>
    <v-snackbar-queue bottom left location="bottom-left" class="d-print-none"></v-snackbar-queue>
    <v-snackbar-queue bottom location="bottom" class="d-print-none"></v-snackbar-queue>
  </div>
</template>

<script>
import MainMenu from '@/components/MainMenu'

export default {
  data: ()=>({
    isMiniVariant: false,
    publicPath: process.env.BASE_URL
  }),
  components: {
    MainMenu,
  },
  created () {
    this.$store.dispatch('startAlertTimer')
  },
  computed: {

  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(()=>{
        window.location.reload()
      })
    }
  },
  beforeMount() {
    this.isMiniVariant = (this.$vuetify.breakpoint.mdAndDown || this.$vuetify.breakpoint.width<this.$vuetify.breakpoint.height)
  },
}
</script>

<style lang="css">
</style>
