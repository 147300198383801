import 'globalthis/auto'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import tanteeCommon from 'tantee-common'
import tanteePermission from 'tantee-common/lib/plugins/permission'
import tanteePrint from 'tantee-common/lib/plugins/print'
import installLocalComponent from 'tantee-common/lib/framework/localComponent'

import dialog from './plugins/_dialogsLoader'
import './plugins/config'


Vue.config.productionTip = false

Vue.use(tanteeCommon)
Vue.use(tanteePermission)
Vue.use(tanteePrint)

installLocalComponent()

new Vue({
  router,
  store,
  vuetify,
  ...dialog,
  render: h => h(App)
}).$mount('#app')
