export let moduleMenu = [
  {
    title: 'Covid Vaccine Clinic',
    action: 'mdi-shield-account',
    menuItems: [
      {
        title: 'ตรวจสอบนัดหมาย',
        link: { name: 'covidvaccine.appointcheck' },
        action: 'mdi-account-clock',
      },
      {
        title: 'ลงทะเบียนและขอความยินยอม',
        link: { name: 'covidvaccine.registration' },
        action: 'mdi-file-document-edit-outline',
        permission: 'covid19vaccine-registration',
      },
      {
        title: 'หน่วยฉีดวัคซีน',
        link: { name: 'covidvaccine.administration' },
        action: 'mdi-needle',
        permission: 'covid19vaccine-administration',
      },
      {
        title: 'สังเกตอาการและจำหน่าย',
        link: { name: 'covidvaccine.discharge' },
        action: 'mdi-timer-outline',
        permission: 'covid19vaccine-discharge',
      },
      {
        title: 'บันทึกอาการไม่พึงประสงค์',
        link: { name: 'covidvaccine.adverseevent' },
        action: 'mdi-emoticon-sick-outline',
        permission: 'covid19vaccine-adverseevent',
      },
      {
        title: 'รายงานผู้รับวัคซีน',
        link: { name: 'covidvaccine.patientslist'},
        action: 'mdi-clipboard-list-outline',
        permission: 'covid19vaccine-patientslist',
      },
      {
        title: 'ประวัติการให้วัคซีน',
        link: { name: 'covidvaccine.adminhistory' },
        action: 'mdi-calendar-clock-outline',
        permission: 'covidvaccine-adminhistory',
      },
      {
        title: 'รายงานการส่งข้อมูลไป MOPH',
        link: { name: 'covidvaccine.mophsent'},
        action: 'mdi-cube-send',
        permission: 'covid19vaccine-mophsent',
      },
    ],
    permission: 'covid19vaccine',
  },
]

export let moduleRoute = [
  {
    path: '/covidvaccine/AppointCheck',
    name: 'covidvaccine.appointcheck',
    component: () => import('./AppointCheck')
  },
  {
    path: '/covidvaccine/Registration',
    name: 'covidvaccine.registration',
    component: () => import('./Registration')
  },
  {
    path: '/covidvaccine/Administration',
    name: 'covidvaccine.administration',
    component: () => import('./Administration'),
    props: true
  },
  {
    path: '/covidvaccine/Discharge',
    name: 'covidvaccine.discharge',
    component: () => import('./Discharge')
  },
  {
    path: '/covidvaccine/AdverseEvent',
    name: 'covidvaccine.adverseevent',
    component: () => import('./AdverseEvent')
  },
  {
    path: '/covidvaccine/PatientsList',
    name: 'covidvaccine.patientslist',
    component: () => import('./PatientsList')
  },
  {
    path: '/covidvaccine/AdminHistory',
    name: 'covidvaccine.adminhistory',
    component: () => import('./AdminHistory')
  },
  {
    path: '/covidvaccine/MophSent',
    name: 'covidvaccine.mophsent',
    component: () => import('./MophSent')
  },
]