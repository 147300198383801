<template lang="html">
  <v-model-table model-name="Appointments" model-title="รายการนัดหมาย"
    :model-api="['models','Appointment','Appointments']"
    :model-api-search="searchQuery" :initial-data="initialData"
    :headers="headers" :limit-total-items="1"
    v-if="hn">
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="4">
            <v-master-field v-model="data.clinicCode" :custom-api="['models','Master','Clinics','query']"
            custom-api-text="clinicName" custom-api-value="clinicCode"
            :filter-text="{encounterType: 'AMB'}"
            :value-object.sync="currentClinic"
            customApiEagerLoad
            :rules="[rules.require()]"
            label="คลินิก" show-code></v-master-field>
          </v-col>
          <v-col cols="4">
            <v-master-field v-model="data.doctorCode" :custom-api="['models','Master','Doctors','query']"
            custom-api-text="nameTH" custom-api-value="doctorCode"
            :filter-text="(currentClinic && currentClinic.specialty) ? {specialty: currentClinic.specialty} : undefined"
            customApiEagerLoad
            :rules="[rules.require()]"
            label="แพทย์" show-code></v-master-field>
          </v-col>
          <v-col cols="4">
            <v-master-field :rules="[rules.require()]" v-model="data.appointmentType" label="ชนิดนัด" :groupKey="$store.getters.getGroupKey('appointmentType')"></v-master-field>
          </v-col>
          <v-col cols="4">
            <v-date-time-field :rules="[rules.require(),ruleOnlyFutureDate(data.appointmentDateTime)]" v-model="data.appointmentDateTime" append-icon="event" label="วัน-เวลานัดหมาย"></v-date-time-field>
          </v-col>
          <v-col cols="4">
            <v-master-field v-model="data.appointmentActivity" label="กิจกรรม" :groupKey="$store.getters.getGroupKey('appointmentActivity')"></v-master-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.appointmentDateTime="props">
      <v-label-datetime :date-time="props.item.appointmentDateTime" short-date-time></v-label-datetime>
    </template>
    <template v-slot:item.appointmentType="props">
      <v-label-master group-key="appointmentType" :item-code="props.item.appointmentType"></v-label-master>
    </template>
    <template v-slot:item.appointmentActivity="props">
      <v-label-master group-key="appointmentActivity" :item-code="props.item.appointmentActivity"></v-label-master>
    </template>
    <template v-slot:item.action="props">
      <v-icon class="mx-1" @click.stop="printPassport(props.item.hn)">print</v-icon>
      <v-icon class="mx-1" @click.stop="props.actions.editData(props.item)" :disabled="props.item.to_encounter && props.item.to_encounter.length>0">$vuetify.icons.edit</v-icon>
      <v-icon class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)"  :disabled="props.item.to_encounter && props.item.to_encounter.length>0">$vuetify.icons.delete</v-icon>
    </template>
  </v-model-table>
  <v-card v-else>
    <v-card-text></v-card-text>
  </v-card>
</template>

<script>
import helperDateTime from 'tantee-common/lib/helperDateTime'
import printDocument from '@/components/mixins/printDocument'
import api from 'tantee-common/lib/api'

export default {
  mixins: [printDocument],
  data: ()=>({
    headers: [
      {
        text: 'เวลานัดหมาย',
        value: 'appointmentDateTime',
        class: 'body-2',
      },
      {
        text: 'ระยะเวลา (นาที)',
        value: 'appointmentDuration',
        class: 'body-2',
      },
      {
        text: 'คลินิก',
        value: 'clinic.clinicName',
        class: 'body-2',
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'ชนิดการนัด',
        value: 'appointmentType',
        class: 'body-2'
      },
      {
        text: 'กิจกรรม',
        value: 'appointmentActivity',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    labHeaders: [
      {
        text: 'รายการตรวจ',
        value: 'productCode',
        class: 'body-2',
      },
    ],
    xrayHeaders: [
      {
        text: 'รายการตรวจ',
        value: 'productCode',
        class: 'body-2',
      },
    ],
    currentClinic: undefined,
    currentAdditionalDetailTab: undefined,
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    encounterId: {
      type: String,
      default: undefined
    },
    clinicCode: {
      type: String,
      default: undefined
    },
    doctorCode: {
      type: String,
      default: undefined
    }
  },
  computed: {
    initialData() {
      return {
        hn: this.hn,
        clinicCode: this.clinicCode,
        doctorCode: this.doctorCode,
      }
    },
    searchQuery() {
      return {
        hn: this.hn,
        'whereDate#appointmentDateTime#>=': helperDateTime.today().format('YYYY-MM-DD')
      }
    },
  },
  methods: {
    ruleOnlyFutureDate(value) {
      return v => !v || helperDateTime.parseDateTime(value).isAfter(helperDateTime.today()) || 'Should be after now'
    },
    printPassport(hn) {
      api.post(['Covid19','VaccineController','generateVaccinePassport'],{hn: hn}).then((result)=>{
        if (result.success) {
          this.printDocument(result.data.id)
        }
      }).catch((e)=>{
        void e
      })
    }
  },
}
</script>

<style lang="css">
</style>
