<template lang="html">
  <v-img :src="imgSrc" contain max-height="100%" v-if="startsWith(mimeType,'image')"></v-img>
  <div align="center" v-else-if="mimeType=='application/pdf'">
    <pdf :src="imgSrc"></pdf>
  </div>
  <div align="center" v-else>
    <v-icon :size="150">mdi-file-document-box</v-icon>
  </div>
</template>

<script>
import { isPlainObject,isUndefined,startsWith } from 'lodash'
import helperAsset from '@/modules/helperAsset'
import pdf from 'vue-pdf'
import api from 'tantee-common/lib/api'

export default {
  components: {
    pdf
  },
  data: ()=>({
    imgSrc: undefined,
    isLoading: false,
  }),
  props: {
    asset: {
      type: [Object, Array],
      default: ()=>({})
    },
  },
  computed: {
    mimeType() {
      if (!isUndefined(this.asset) && !isUndefined(this.asset['mimeType'])) {
        return this.asset['mimeType']
      } else {
        return undefined
      }
    }
  },
  methods: {
    onDelete() {
      this.$emit('delete')
    },
    loadImgSrc() {
      if (!isUndefined(this.asset)) {
        if (isUndefined(this.asset['base64data'])) {
          if (!isUndefined(this.asset['id'])) {
            // this.isLoading = true
            // helperAsset.getBase64data(this.asset).then((returnData)=>{
            //   this.imgSrc = returnData['base64data']
            // }).finally(()=>{
            //   this.isLoading = false
            // })
            this.imgSrc = api.urlBuilder(['assets',this.asset['id']])
          }
        } else {
          this.imgSrc = this.value['base64data']
        }
      } else {
        this.imgSrc = undefined
      }
    },
    startsWith(str,target) {
      return startsWith(str,target)
    }
  },
  watch: {
    asset: {
      handler(newVal) {
        this.loadImgSrc()
      },
      deep: true
    },
  },
  mounted() {
    this.loadImgSrc()
  },
}
</script>

<style lang="css" scoped>
</style>
