<template lang="html">
  <v-model-iterator model-name="vitalSigns" model-title="Vital Signs"
    :model-api="['models','Patient','PatientsVitalsigns']"
    :model-api-search="vitalSignQuery" 
    api-order-by="vitalSignDateTime,DESC"
    :initial-data="initialData"
    :dialog-width="($vuetify.breakpoint.lgAndUp) ? '30vw' : '80vw'"
    v-if="hn"
    flat
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container fluid pa-0>
        <v-row dense>
          <v-col cols="12">
            <v-text-field ref="temperature" @keydown.enter.prevent="$refs.systolic.focus()" v-model="data.temperature" :rules="[rules.numeric(),rules.range(30,45)]" label="Temperature" append="°C" autofocus></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field ref="systolic" @keydown.enter.prevent="$refs.diastolic.focus()" v-model="data.bloodPressureSystolic" :rules="[rules.numeric()]" label="BP Systolic" append="mmHg"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field ref="diastolic" @keydown.enter.prevent="$refs.heartRate.focus()" v-model="data.bloodPressureDiastolic" :rules="[rules.numeric()]" label="BP Diastolic" append="mmHg"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field ref="heartRate" @keydown.enter.prevent="$refs.weight.focus()" v-model="data.heartRate" :rules="[rules.numeric()]" label="Heart Rate" append="/min"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="!showOptional">
          <v-col cols="12">
            <v-btn text @click="showOptional=true" tabindex="-1" color="grey"><v-icon>mdi-dots-horizontal</v-icon> more</v-btn>
          </v-col>
        </v-row>
        <v-row dense v-if="showOptional">
          <v-col cols="12">
            <v-text-field ref="weight" @keydown.enter.prevent="$refs.height.focus()" v-model="data.weight" :rules="[rules.numeric()]" label="Weight" append="Kg"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="showOptional">
          <v-col cols="12">
            <v-text-field ref="height" @keydown.enter.prevent="$refs.respiratory.focus()" v-model="data.height" :rules="[rules.numeric()]" label="Height" append="cm"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="showOptional">
          <v-col cols="12">
            <v-text-field ref="respiratory" @keydown.enter.prevent="$refs.o2sat.focus()" v-model="data.respiratoryRate" :rules="[rules.numeric()]" label="Respiratory Rate" append="/min"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="showOptional">
          <v-col cols="12">
            <v-text-field ref="o2sat" v-model="data.oxygenSaturation" :rules="[rules.numeric()]" label="O2 Saturation" append="%"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item="props">
      <v-row dense>
        <v-col cols="12" md="4" lg="2">
          <v-chip><v-label-datetime :date-time="props.item.vitalSignDateTime" short-date-time></v-label-datetime></v-chip>
        </v-col>
        <v-col cols="12" md="8" lg="10">
          <v-row dense>
            <v-col cols="12" sm="6" md="2">
              <v-label-value label="Temp" append="°C" minimum="36.5" maximum="37.8">{{ props.item.temperature }}</v-label-value>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-label-value label="Height" append="cm">{{ props.item.height || '-' }}</v-label-value>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-label-value label="Weight" append="Kg">{{ props.item.weight || '-' }}</v-label-value>
            </v-col>
            <v-col cols="12" sm="3" md="2">
              <v-label-value label="BSA" v-if="calculatedBSA(props.item)">{{ calculatedBSA(props.item) }}</v-label-value>
            </v-col>
            <v-col cols="12" sm="3" md="4">
              <v-label-value label="BMI" v-if="calculatedBMI(props.item)">{{ calculatedBMI(props.item) }}</v-label-value>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-label-value label="HR" append="/min" minimum="60" maximum="120">{{ props.item.heartRate || '-' }}</v-label-value>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-label-value label="RR" append="/min" minimum="16" maximum="24">{{ props.item.respiratoryRate || '-' }}</v-label-value>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-label-value label="BP" minimum="90" maximum="140">{{ props.item.bloodPressureSystolic || '-' }}</v-label-value> /
              <v-label-value append="mmHg" minimum="60" maximum="90">{{ props.item.bloodPressureDiastolic || '-' }}</v-label-value>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-label-value label="MAP" minimum="70" maximum="110">{{ calculatedMAP(props.item) || '-' }}</v-label-value>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-label-value label="O2 Sat" append="%" minimum="92">{{ props.item.oxygenSaturation || '-' }}</v-label-value>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-label-value label="PainScore" maximum=3>{{ props.item.painScore || '-' }}</v-label-value>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </v-model-iterator>
</template>

<script>
import helperDateTime from 'tantee-common/lib/helperDateTime'

export default {
  data: ()=>({
    vitalsignHeader: [
      {
        text: 'Time',
        value: 'vitalSignDateTime',
        valueTransform: (data) => {
          return helperDateTime.toString(data,null,'HH:mm น.')
        },
        class: 'body-2',
      },
      {
        text: 'Temp (°C)',
        value: 'temperature',
        class: 'body-2'
      },
      {
        text: 'Height (cm)',
        value: 'height',
        class: 'body-2'
      },
      {
        text: 'Weight (Kg)',
        value: 'weight',
        class: 'body-2'
      },
      {
        text: 'HR (/min)',
        value: 'heartRate',
        class: 'body-2'
      },
      {
        text: 'RR (/min)',
        value: 'respiratoryRate',
        class: 'body-2'
      },
      {
        text: 'BP Systolic (mmHg)',
        value: 'bloodPressureSystolic',
        class: 'body-2'
      },
      {
        text: 'BP Diastolic (mmHg)',
        value: 'bloodPressureDiastolic',
        class: 'body-2'
      },
      {
        text: 'O2 Sat (%)',
        value: 'oxygenSaturation',
        class: 'body-2'
      },
      {
        text: 'PS',
        value: 'painScore',
        class: 'body-2'
      },
    ],
    showOptional: false,
  }),
  methods:{
    calculatedBSA(vitalSign) {
      if (vitalSign.height && vitalSign.weight) {
        return Math.sqrt(vitalSign.height*vitalSign.weight/3600).toFixed(2)
      } else {
        return null
      }
    },
    calculatedMAP(vitalSign) {
      if (vitalSign.bloodPressureSystolic && vitalSign.bloodPressureDiastolic) {
        return Math.round(((2*vitalSign.bloodPressureDiastolic)+vitalSign.bloodPressureSystolic)/3)
      } else {
        return null
      }
    },
    calculatedBMI(vitalSign) {
      if (vitalSign.height && vitalSign.weight) {
        return (vitalSign.weight/(Math.pow(vitalSign.height/100,2))).toFixed(2)
      } else {
        return null
      }
    }
  },
  props: {
    hn: {
      type: String,
      default: undefined
    },
  },
  computed: {
    initialData() {
      return {
        hn: this.hn,
      }
    },
    vitalSignQuery() {
      return {
        hn: this.hn,
        'where#vitalSignDateTime#>=': helperDateTime.now().subtract(1,'days').format('YYYY-MM-DD HH:mm'),
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
