<template lang="html">
  <v-card :flat="flat">
    <v-card-title primary-title v-if="!hideToolbar">
      <v-icon v-if="!currentDocumentId">mdi-file-plus</v-icon>
      {{ title }}
      <v-btn icon @click="printCurrentDocument" v-if="showPrintButton && currentTemplate">
        <v-icon>print</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-master-field v-model="currentTemplateCode"
        :custom-api="['models','Document','DocumentsTemplates','query']"
        custom-api-text="templateName"
        custom-api-value="templateCode" 
        label="เอกสาร" show-code customApiEagerLoad
        v-if="!currentTemplate && !templateCode && !documentId"
      >
      </v-master-field>
      &nbsp;
      <v-btn
        fab
        small
        @click="isEditing = !isEditing"
        v-if="!readOnly && currentDocumentId"
      >
        <v-icon v-if="isEditing">mdi-close</v-icon>
        <v-icon v-else>mdi-pencil</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="!isLoading" class="pa-0 pa-sm-2 pa-md-4">
      <v-form-pad ref="formPad" v-model="apiItemData.data" :disabled="!canEdit" :documentStatus="(apiItemData && apiItemData.status) ? apiItemData.status : 'draft'" v-slot="{data,rules}" :template="documentTemplate" :templateScript="documentTemplateScript">
        <slot :data="data" :rules="rules" :disabled="!isEditing"></slot>
      </v-form-pad>
    </v-card-text>
    <v-card-actions v-if="apiItemData.id">
      <v-spacer></v-spacer>
      <span class="body-1">
        <span class="font-weight-bold">บันทึกโดย</span>
        <v-label-user :username="apiItemData.created_by"></v-label-user>
        <template v-if="apiItemData.updated_by">
          <span class="font-weight-bold">ปรับปรุงโดย</span>
          <v-label-user :username="apiItemData.updated_by"></v-label-user>
        </template>
      </span>
    </v-card-actions>
    <v-card-text v-if="!currentTemplate && !currentTemplateCode">
      กรุณาเลือกชนิดเอกสาร
    </v-card-text>
    <v-card-actions v-if="isEditing && !readOnly && currentTemplate && (!hideAction || isShowUnapprove)">
      <v-btn color="primary" @click="saveDocument" :loading="isSaving" :disabled="!isItemDataChange" v-if="canSave">Save</v-btn>
      <v-btn color="success" @click="approveDocument(false)" v-if="canApprove && (!approveOnSave || onReview)">Approve</v-btn>
      <v-btn color="warning" @click="unapproveDocument(false)" v-if="canUnapprove">Unapprove</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { isUndefined,isNull,isEqual,cloneDeep,isPlainObject } from 'lodash'
import printDocument from '@/components/mixins/printDocument'
import helperItem from 'tantee-common/lib/helperItem'
import apiItem from 'tantee-common/lib/mixins/apiItem'

export default {
  mixins: [apiItem,printDocument],
  data: ()=>({
    apiBaseUrl: ['models','Document','Documents'],
    apiWith: 'template',
    currentDocumentId: undefined,
    currentTemplateCode: undefined,
    currentTemplate: undefined,
    isLoading: false,
    isEditing: true,
    isSaving: false,
  }),
  model: {
    prop: 'documentId',
    event: 'input'
  },
  props: {
    documentId: {
      type: [String,Number],
      default: undefined
    },
    templateCode: {
      type: String,
      default: undefined
    },
    folder: {
      type: String,
      default: 'default'
    },
    referenceId: {
      type: String,
      default: undefined
    },
    hn: {
      type: String,
      default: undefined
    },
    encounterId: {
      type: String,
      default: undefined
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    ownerOnly: {
      type: Boolean,
      default: false
    },
    editTimeout: {
      type: Number,
      default: undefined
    },
    initialData: {
      type: Object,
      default: undefined
    },
    initialDataFromTemplateCode: {
      type: String,
      default: undefined
    },
    flat: {
      type: Boolean,
      default: false
    },
    approveOnSave: {
      type: Boolean,
      default: false
    },
    hideToolbar: {
      type: Boolean,
      default: false
    },
    hideAction: {
      type: Boolean,
      default: false
    },
    showUnapprove: {
      type: Boolean,
      default: false
    },
    findLatest: {
      type: [Boolean, Object],
      default: false
    },
    signToSave: {
      type: Boolean,
      default: true
    },
    showPrintButton: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    templateCode: function(newVal) {
      if (isUndefined(this.currentDocumentId)) {
        this.loadDocumentTemplate(newVal)
      }
    },
    currentTemplateCode: function(newVal) {
      if (isUndefined(this.currentDocumentId)) {
        this.loadDocumentTemplate(newVal)
      }
    },
    documentId: function(newVal) {
      this.currentDocumentId = newVal
      this.loadDocument(newVal)
    },
    isEditing: function (newVal) {
      if (!newVal) {
        if (this.isItemDataChange) {
          this.$confirm('มีการแก้ไขข้อมูล ต้องการที่จะบันทึกหรือไม่').then((res)=>{
            if (res) {
              this.saveDocument()
            } else {
              this.apiItemData = cloneDeep(this.apiItemOriginal)
            }
          })
        }
      } else {
        this.apiItemData = cloneDeep(this.apiItemOriginal)
      }
    },
    initialData: {
      handler: function() {
        if (!this.currentDocumentId) {
          this.$set(this.apiItemData,'data',Object.assign({},this.apiItemData.data,this.initialData))
        }
      },
      deep: true
    },
    initialDataFromTemplateCode: function () {
      this.loadInitialData()
    },
    findLatest: {
      handler: function() {
        if (isUndefined(this.currentDocumentId) && this.templateCode) {
          this.loadDocumentTemplate(this.templateCode)
        }
      },
      deep: true
    }
  },
  computed: {
    documentTemplate: function() {
      if (this.currentTemplate) {
        if (!this.canEdit && this.currentTemplate.viewTemplate) return this.currentTemplate.viewTemplate
        else return this.currentTemplate.editTemplate
      } else {
        return undefined
      }
    },
    documentTemplateScript: function() {
      if (this.currentTemplate) {
        return this.currentTemplate.templateScript
      } else {
        return undefined
      }
    },
    newDocumentData: function() {
      return {
        hn: this.hn,
        encounterId: this.encounterId,
        templateCode: this.currentTemplate.templateCode,
        category: this.currentTemplate.defaultCategory,
        folder: this.folder,
        referenceId: this.referenceId,
      }
    },
    title: function() {
      let titlePrefix = (this.apiItemData && (!this.apiItemData.status || this.apiItemData.status=='approved')) ? '' : '['+this.apiItemData.status.toUpperCase()+']'
      return (this.currentTemplate) ? titlePrefix+' '+this.currentTemplate.templateName : 'New document'
    },
    canEdit: function() {
      return !this.readOnly && this.isEditing && (this.canSave || this.canApprove)
    },
    canSave: function() {
      return this.apiItemData && this.apiItemData.status!='approved' && ((this.currentDocumentId && this.$store.getters.getCurrentUser && this.apiItemData.created_by==this.$store.getters.getCurrentUser.username) || !this.ownerOnly || !this.currentDocumentId)
    },
    canApprove: function() {
      return this.apiItemData && this.apiItemData.status!='approved' && this.currentDocumentId && ((this.$store.getters.getCurrentUser && this.apiItemData.created_by==this.$store.getters.getCurrentUser.username) || !this.ownerOnly)
    },
    canUnapprove: function() {
      return this.apiItemData && this.apiItemData.status=='approved' && this.currentDocumentId && ((this.$store.getters.getCurrentUser && this.apiItemData.created_by==this.$store.getters.getCurrentUser.username) || !this.ownerOnly)
    },
    onReview: function() {
      return this.apiItemData && this.apiItemData.status=='review'
    },
    isShowUnapprove: function() {
      return this.apiItemData && this.apiItemData.status=='approved' && this.showUnapprove
    }
  },
  methods: {
    loadInitialData() {
      if (!this.currentDocumentId) {
        if (this.initialDataFromTemplateCode && this.hn) {
          let searchQuery = {
            hn: this.hn,
            templateCode: this.initialDataFromTemplateCode,
            folder: this.folder
          }
          helperItem.firstItem(['models','Document','Documents'],searchQuery).then((returnData)=>{
            this.$set(this.apiItemData,'data',Object.assign({},this.initialData,returnData.data))
          }).catch((e)=>{
            this.$set(this.apiItemData,'data',Object.assign({},this.initialData))
            void e
          })
        } else {
          this.$set(this.apiItemData,'data',Object.assign({},this.initialData))
        }
      }
    },
    loadDocumentTemplate(templateCode) {
      if (!isUndefined(templateCode)) {
        helperItem.findItem(['models','Document','DocumentsTemplates'],templateCode).then((returnData)=>{
          this.currentTemplate = returnData
          if (this.findLatest) {
            this.isLoading = true

            let findLatestData = Object.assign({},this.newDocumentData)
            if (isPlainObject(this.findLatest)) Object.assign(findLatestData,this.findLatest)

            this.firstItem(findLatestData,true).then((returnData)=>{
              this.currentDocumentId = returnData.id
              this.currentTemplate = returnData.template
              if (this.readOnly) this.isEditing = false
            }).catch((e)=>{
              this.currentDocumentId = undefined
              this.loadInitialData()
              void e
            }).finally(()=>{
              this.isLoading = false
            })
          } else {
            this.loadInitialData()
          }
        }).catch((e)=>{
          void e
        })
      }
    },
    loadDocument(documentId) {
      if (!isUndefined(documentId) && !isNull(documentId)) {
        this.isLoading = true
        this.findItem(documentId).then((returnData)=>{
          this.currentDocumentId = returnData.id
          this.currentTemplate = returnData.template
          if (this.readOnly) this.isEditing = false
        }).catch((e)=>{
          this.currentDocumentId = undefined
          void e
        }).finally(()=>{
          this.isLoading = false
        })
      }
    },
    saveDocument() {
      return new Promise((resolve,reject)=>{
        this.isSaving = true

        if (!this.canSave) {
          this.$notify('ไม่สามารถบันทึกข้อมูลได้ กรุณาตรวจสอบสถานะ',{
            title: 'ผิดพลาด',
            icon: 'error',
            color: 'warning',
          })
          this.isSaving = false
          reject()
          return
        }

        if (this.$refs.formPad.validate()) {
          this.$nextTick(()=>{
            let saveData = (this.currentDocumentId) ? this.apiItemData : Object.assign({},this.newDocumentData,this.apiItemData)

            if (this.signToSave) {
              this.$userCheck(false,true).then((res)=>{
                if (res) {
                  if (this.approveOnSave) saveData['status'] = 'approved'
                  this.replaceItem(saveData).then((returnData)=>{
                    this.currentDocumentId = returnData.id
                    this.currentTemplate = returnData.template

                    this.$emit('input',this.currentDocumentId)
                    this.$nextTick(()=>{
                      this.$emit('documentSaved',this.apiItemData)
                      if (this.approveOnSave) this.$emit('documentApproved',this.apiItemData)
                    })
                    resolve(this.apiItemData)
                  }).catch((e)=>{
                    void e
                    reject()
                  }).finally(()=>{
                    this.isSaving = false
                  })
                }
              }).finally(()=>{
                this.isSaving = false
              })
            } else {
              this.replaceItem(saveData).then((returnData)=>{
                this.currentDocumentId = returnData.id
                this.currentTemplate = returnData.template

                this.$emit('input',this.currentDocumentId)
                this.$nextTick(()=>{
                  this.$emit('documentSaved',this.apiItemData)
                })
                resolve(this.apiItemData)
              }).catch((e)=>{
                void e
                reject()
              }).finally(()=>{
                this.isSaving = false
              })
            }            
          })
        } else {
          this.$notify('กรุณาตรวจสอบ และกรอกข้อมูลให้ครบถ้วน',{
            title: 'ผิดพลาด',
            icon: 'error',
            color: 'warning',
          })
          this.isSaving = false
          reject()
        }
      })
    },
    approveDocument(silent=false) {
      if (this.apiItemData.id) {
        let saveData = {
          id: this.apiItemData.id,
          data: this.apiItemData.data,
          status: 'approved'
        }

        if (!this.canApprove) {
          this.$notify('ไม่สามารถ Approve เอกสารได้',{
            title: 'ผิดพลาด',
            icon: 'error',
            color: 'warning',
          })
          return
        }

        if (!silent) {
          this.$userCheck(false,true).then((res)=>{
            if (res) {
              this.updateItem(saveData).then(()=>{
                this.$emit('documentApproved',this.apiItemData)
              }).catch((e)=>{
                void e
              })
            }
          })
        } else {
          this.updateItem(saveData,true).then(()=>{
            this.$emit('documentApproved',this.apiItemData)
          }).catch((e)=>{
            void e
          })
        }
      }
    },
    unapproveDocument(silent=false) {
      if (this.apiItemData.id) {
        let saveData = {
          id: this.apiItemData.id,
          status: 'review'
        }

        if (!this.canUnapprove) {
          this.$notify('ไม่สามารถ Unapprove เอกสารได้',{
            title: 'ผิดพลาด',
            icon: 'error',
            color: 'warning',
          })
          return
        }

        if (!silent) {
          this.$userCheck(false,true).then((res)=>{
            if (res) {
              this.updateItem(saveData).then(()=>{
                this.$emit('documentUnapproved',this.apiItemData)
              }).catch((e)=>{
                void e
              })
            }
          })
        } else {
          this.updateItem(saveData,true).then(()=>{
            this.$emit('documentUnapproved',this.apiItemData)
          }).catch((e)=>{
            void e
          })
        }
      }
    },
    reset(resetTemplate = false) {
      this.resetItem()
      this.currentDocumentId = undefined
      if (resetTemplate) {
        this.currentTemplate = undefined
        this.currentTemplateCode = undefined
        this.loadDocumentTemplate(this.templateCode)
      }
      this.$emit('input',this.currentDocumentId)
    },
    printCurrentDocument() {
      if (this.currentDocumentId && !this.isItemDataChange) {
        this.printDocument(this.currentDocumentId)
      } else {
        if (this.currentDocumentId) {
          this.$confirm('มีการแก้ไขข้อมูล ต้องการที่จะบันทึกหรือไม่').then((res)=>{
            if (res) {
              this.saveDocument().then(()=>{
                this.printDocument(this.currentDocumentId)
              }).catch((e)=>{
                void e
              })
            } else {
              this.printTemporary(this.hn,this.encounterId,this.currentTemplate.templateCode,this.apiItemData.data)
            }
          })
        } else {
          if (this.currentTemplate) {
            this.printTemporary(this.hn,this.encounterId,this.currentTemplate.templateCode,this.apiItemData.data)
          }
        } 
      }
    }
  },
  beforeMount() {
    if (this.documentId && this.currentDocumentId!=this.documentId) this.loadDocument(this.documentId)
    if (!this.currentDocumentId && this.templateCode) this.loadDocumentTemplate(this.templateCode)
  },
}
</script>

<style lang="css" scoped>
</style>
