export let moduleMenu = [
  {
    title: 'Covid Vaccine Admin',
    action: 'mdi-duck',
    menuItems: [
      {
        title: 'สรุปยอดนัดหมาย',
        link: { name: 'covidvaccineadmin.appointmentsummary' },
        action: 'mdi-calendar-multiple',
        permission: 'covid19vaccine-admin-appointmentsummary',
      },
      {
        title: 'นัดหมายผู้รับบริการแบบกลุ่ม',
        link: { name: 'covidvaccineadmin.appointgroup' },
        action: 'mdi-account-group',
        permission: 'covid19vaccine-admin-appointgroup',
      },
      {
        title: 'นัดหมายเพิ่มเติมพิเศษ',
        link: { name: 'covidvaccineadmin.appointvip' },
        action: 'mdi-emoticon-cool-outline',
        permission: 'covid19vaccine-admin-appointvip',
      },
      {
        title: 'ขอรวม/เปลี่ยนเลขประจำตัว',
        action: 'mdi-swap-horizontal',
        link: { name: 'covidvaccineadmin.mergesrequests' },
        permission: 'covid19vaccine-admin-mergesrequests',
      },
    ],
    permission: 'covid19vaccine-admin',
  },
]

export let moduleRoute = [
  {
    path: '/covidvaccine/AppointmentSummary',
    name: 'covidvaccineadmin.appointmentsummary',
    component: () => import('./AppointmentSummary')
  },
  {
    path: '/covidvaccine/AppointGroup',
    name: 'covidvaccineadmin.appointgroup',
    component: () => import('./AppointGroup')
  },
  {
    path: '/covidvaccine/Registration',
    name: 'covidvaccineadmin.appointvip',
    component: () => import('./AppointVip')
  },
  {
    path: '/covidvaccine/MergesRequests',
    name: 'covidvaccineadmin.mergesrequests',
    component: () => import('./MergesRequests')
  },
]