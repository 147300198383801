<template lang="html">
  <input type="hidden" v-bind:value="value" v-bind="$attrs" v-on="$listeners">
</template>

<script>
import {isUndefined} from 'lodash'
import gs1js from 'gs1js'
import moment from 'moment'

export default {
  props: {
    itemValue: {
      default: undefined
    },
    value: {
      default: undefined
    }
  },
  watch: {
    itemValue: {
      handler() {
        this.emitValue()
      },
      deep: true
    }
  },
  methods: {
    emitValue() {
      if (this.itemValue) {
        let returnValue = this.itemValue.toUpperCase()
        if ((typeof returnValue === 'string' || returnValue instanceof String)) {
          if (returnValue.startsWith('0100000000000000') && returnValue.substring(36,38)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,36)+decodeURI("%1D")+returnValue.substring(36)
          if (returnValue.startsWith('0108806507011325') && returnValue.substring(34,36)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,34)+decodeURI("%1D")+returnValue.substring(34)
          if (returnValue.startsWith('0105000456068253') && returnValue.substring(31,33)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,31)+decodeURI("%1D")+returnValue.substring(31)
          else if (returnValue.startsWith('0105000456068253') && returnValue.substring(32,34)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,32)+decodeURI("%1D")+returnValue.substring(32)
          if (returnValue.startsWith('0155555555555555') && returnValue.substring(36,38)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,36)+decodeURI("%1D")+returnValue.substring(36)
          if (returnValue.startsWith('0114987222001912') && returnValue.substring(31,33)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,31)+decodeURI("%1D")+returnValue.substring(31)
          if (returnValue.startsWith('0114987222002001') && returnValue.substring(31,33)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,31)+decodeURI("%1D")+returnValue.substring(31)
          if (returnValue.startsWith('0100359267100023') && returnValue.substring(33,35)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,33)+decodeURI("%1D")+returnValue.substring(33)
          else if (returnValue.startsWith('0100359267100023') && returnValue.substring(32,34)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,32)+decodeURI("%1D")+returnValue.substring(32)
          else if (returnValue.startsWith('0100359267100023') && returnValue.substring(31,33)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,31)+decodeURI("%1D")+returnValue.substring(31)
          else if (returnValue.startsWith('0100359267100023') && returnValue.substring(30,32)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,30)+decodeURI("%1D")+returnValue.substring(30)
          if (returnValue.startsWith('0104260703260118') && returnValue.substring(33,35)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,33)+decodeURI("%1D")+returnValue.substring(33)
          else if (returnValue.startsWith('0104260703260118') && returnValue.substring(32,34)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,32)+decodeURI("%1D")+returnValue.substring(32)
          if (returnValue.startsWith('0105000456036160') && returnValue.substring(31,33)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,31)+decodeURI("%1D")+returnValue.substring(31)
          else if (returnValue.startsWith('0105000456036160') && returnValue.substring(32,34)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,32)+decodeURI("%1D")+returnValue.substring(32)
          if (returnValue.startsWith('0130380777700701') && returnValue.substring(37,39)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,37)+decodeURI("%1D")+returnValue.substring(37)
          else if (returnValue.startsWith('0130380777700701') && returnValue.substring(36,38)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,36)+decodeURI("%1D")+returnValue.substring(36)
          else if (returnValue.startsWith('0130380777700701') && returnValue.substring(35,37)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,35)+decodeURI("%1D")+returnValue.substring(35)
          else if (returnValue.startsWith('0130380777700701') && returnValue.substring(33,35)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,33)+decodeURI("%1D")+returnValue.substring(33)
          else if (returnValue.startsWith('0130380777700701') && returnValue.substring(32,34)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,32)+decodeURI("%1D")+returnValue.substring(32)
          if (returnValue.startsWith('0130380777700756') && returnValue.substring(37,39)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,37)+decodeURI("%1D")+returnValue.substring(37)
          else if (returnValue.startsWith('0130380777700756') && returnValue.substring(36,38)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,36)+decodeURI("%1D")+returnValue.substring(36)
          else if (returnValue.startsWith('0130380777700756') && returnValue.substring(35,37)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,35)+decodeURI("%1D")+returnValue.substring(35)
          else if (returnValue.startsWith('0130380777700756') && returnValue.substring(33,35)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,33)+decodeURI("%1D")+returnValue.substring(33)
          else if (returnValue.startsWith('0130380777700756') && returnValue.substring(32,34)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,32)+decodeURI("%1D")+returnValue.substring(32)
          if (returnValue.startsWith('0100380777273983') && returnValue.substring(39,41)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,39)+decodeURI("%1D")+returnValue.substring(39)
          else if (returnValue.startsWith('0100380777273983') && returnValue.substring(38,40)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,38)+decodeURI("%1D")+returnValue.substring(38)
          else if (returnValue.startsWith('0100380777273983') && returnValue.substring(37,39)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,37)+decodeURI("%1D")+returnValue.substring(37)
          else if (returnValue.startsWith('0100380777273983') && returnValue.substring(36,38)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,36)+decodeURI("%1D")+returnValue.substring(36)
          else if (returnValue.startsWith('0100380777273983') && returnValue.substring(35,37)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,35)+decodeURI("%1D")+returnValue.substring(35)
          else if (returnValue.startsWith('0100380777273983') && returnValue.substring(34,36)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,34)+decodeURI("%1D")+returnValue.substring(34)
          else if (returnValue.startsWith('0100380777273983') && returnValue.substring(33,35)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,33)+decodeURI("%1D")+returnValue.substring(33)
          else if (returnValue.startsWith('0100380777273983') && returnValue.substring(32,34)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,32)+decodeURI("%1D")+returnValue.substring(32)
          if (returnValue.startsWith('0100000000000031') && returnValue.substring(35,37)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,35)+decodeURI("%1D")+returnValue.substring(35)
          if (returnValue.startsWith('0105000456072458') && returnValue.substring(30,32)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,30)+decodeURI("%1D")+returnValue.substring(30)
          if (returnValue.startsWith('0118901213080188') && returnValue.substring(27,29)=='17') returnValue = decodeURI("%1D")+returnValue.substring(0,27)+decodeURI("%1D")+returnValue.substring(27)
          else if (returnValue.startsWith('0118901213080188') && returnValue.substring(35,37)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,35)+decodeURI("%1D")+returnValue.substring(35)
          if (returnValue.startsWith('0104260703260132') && returnValue.substring(33,35)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,33)+decodeURI("%1D")+returnValue.substring(33)
          else if (returnValue.startsWith('0104260703260132') && returnValue.substring(32,34)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,32)+decodeURI("%1D")+returnValue.substring(32)
          if (returnValue.startsWith('0104260703260286') && returnValue.substring(33,35)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,33)+decodeURI("%1D")+returnValue.substring(33)
          else if (returnValue.startsWith('0104260703260286') && returnValue.substring(32,34)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,32)+decodeURI("%1D")+returnValue.substring(32)
          if (returnValue.startsWith('0130380777701531') && returnValue.substring(37,39)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,37)+decodeURI("%1D")+returnValue.substring(37)
          else if (returnValue.startsWith('0130380777701531') && returnValue.substring(36,38)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,36)+decodeURI("%1D")+returnValue.substring(36)
          else if (returnValue.startsWith('0130380777701531') && returnValue.substring(35,37)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,35)+decodeURI("%1D")+returnValue.substring(35)
          else if (returnValue.startsWith('0130380777701531') && returnValue.substring(33,35)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,33)+decodeURI("%1D")+returnValue.substring(33)
          else if (returnValue.startsWith('0130380777701531') && returnValue.substring(32,34)=='21') returnValue = decodeURI("%1D")+returnValue.substring(0,32)+decodeURI("%1D")+returnValue.substring(32)
          returnValue = returnValue.replace('↔','')
          
          if (returnValue.length > 70) returnValue = undefined
        }
        if (!isUndefined(returnValue)) {
          try {
            Promise.resolve(this.decodeGs1(returnValue,this.value)).then((result)=>{
              this.$emit('input',result)
            })
          } catch(e) {
            void e
            this.$emit('input',this.itemValue)
          }
        } else {
          this.$emit('input',this.itemValue)
        }
      }
    },
    decodeGs1(code) {
      var myReader = new gs1js.GS1Reader(code);
      return {
        productCode: (myReader.getApplicationIdentifierById('01')) ? myReader.getApplicationIdentifierById('01').value : null,
        lotNo: (myReader.getApplicationIdentifierById('10')) ? myReader.getApplicationIdentifierById('10').value : null,
        serialNo: (myReader.getApplicationIdentifierById('21')) ? myReader.getApplicationIdentifierById('21').value : null,
        expDate: (myReader.getApplicationIdentifierById('17')) ? moment(myReader.getApplicationIdentifierById('17').value,"YYMMDD").format('YYYY-MM-DD') : null,
        scanDateTime: moment().format('YYYY-MM-DD HH:mm')
      }
    }
  },
  mounted() {
    this.emitValue()
  }
}
</script>

<style lang="css" scoped>
</style>
