<template lang="html">
  <v-container fill-height>
    <v-row align="center">
      <v-col>
        <v-img :src="`${publicPath}assets/logo-with-text.svg`" max-weight="70vw" max-height="80vh" contain></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: ()=>({
    publicPath: process.env.BASE_URL
  })
}
</script>

<style lang="css">
</style>
