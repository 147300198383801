<template lang="html">
  <v-card>
    <v-card-text v-if="hn">
      <v-container fluid py-0>
        <v-row align-content="center">
          <v-col align-self="center" cols="12" :md="(showPrintPatientSlip) ? 8 : 9">
            <v-chip class="mr-1 my-1" :class="(readyData.peopleIdCheck) ? 'hidden-sm-and-down' : undefined" :color="(readyData.peopleIdCheck) ? 'green darken-2' : 'red'"  text-color="white">
              <v-icon left v-if="readyData.peopleIdCheck">mdi-card-account-details-outline</v-icon>
              <v-icon left v-else>mdi-card-bulleted-off-outline</v-icon>
              {{ (readyData.peopleIdCheck) ? readyData.peopleIdDetail : 'เลขประจำตัวประชาชน' }}
            </v-chip>
            <v-chip class="mr-1 my-1" :color="(readyData.consentCheck=='eligible') ? 'green darken-2' : (readyData.consentCheck=='non-eligible') ? 'red' : undefined"  :text-color="(readyData.consentCheck=='eligible' || readyData.consentCheck=='non-eligible') ? 'white' : undefined">
              <v-icon left v-if="readyData.consentCheck=='eligible'">mdi-checkbox-marked-circle-outline</v-icon>
              <v-icon left v-else-if="readyData.consentCheck=='non-eligible'">mdi-close-circle-outline</v-icon>
              <v-icon left v-else>mdi-help-circle-outline</v-icon>
              Consent
            </v-chip>
            <v-chip class="mr-1 my-1 hidden-sm-and-down" color="green" outlined>
              ประวัติฉีดวัคซีน
              <v-avatar right :class="(readyData.vaccineHistoryCount==0) ? 'green lighten-4' : (readyData.vaccineHistoryCount==1) ? 'orange lighten-4' : 'red lighten-4'">
                <span :class="(readyData.vaccineHistoryCount==0) ? 'green--text darken-4' : (readyData.vaccineHistoryCount==1) ? 'orange--text darken-4 font-weight-bold' : 'red--text darken-4 font-weight-bold'">{{ (readyData.vaccineHistoryCount) ? readyData.vaccineHistoryCount : '-' }}</span>
              </v-avatar>
            </v-chip>
            <v-chip class="mr-1 my-1" :class="(readyData.appointmentToday) ? 'hidden-sm-and-down' : undefined" :color="(readyData.appointmentToday) ? 'green darken-2' : 'orange'" outlined>
              <span v-if="readyData.appointmentToday">นัด {{ readyData.appointmentSource }}</span>
              <span v-else>นอกนัดหมาย</span>
            </v-chip>
            <v-chip class="mr-1 my-1" :color="(readyData.vaccineSuggestionColor) ? readyData.vaccineSuggestionColor : 'indigo darken-3'" text-color="white" v-if="readyData.vaccineSuggestion">
              {{ readyData.vaccineSuggestion }}
            </v-chip>
            <v-chip class="mr-1 my-1" color="orange" outlined v-for="caution in readyData.cautions" :key="caution">
              {{ caution }}
            </v-chip>
          </v-col>
          <v-col align-self="center" cols="12" :md="(showPrintPatientSlip) ? 4 : 3" class="ma-0 pa-0 text-right">
            <v-btn @click="printCertificate" class="mr-1" color="green" dark v-if="readyData.mophVaccineCertificate">Certificate</v-btn>
            <v-btn @click="printPatientSlip" class="mr-1" color="primary" dark v-if="readyData && showPrintPatientSlip">พิมพ์ใบนำทาง</v-btn>
            <slot></slot>
          </v-col>
        </v-row>
        <v-row v-if="vitalSigns">
          <v-col>
            <v-label-value label="Temp" append="°C" minimum="36.5" maximum="37.8">{{ vitalSigns.temperature || '-' }}</v-label-value>
          </v-col>
          <v-col>
            <v-label-value label="Height" append="cm">{{ vitalSigns.height || '-' }}</v-label-value>
          </v-col>
          <v-col>
            <v-label-value label="Weight" append="Kg">{{ vitalSigns.weight || '-' }}</v-label-value>
          </v-col>
          <v-col>
            <v-label-value label="BMI">{{ calculatedBMI(vitalSigns) || '-' }}</v-label-value>
          </v-col>
          <v-col>
            <v-label-value label="HR" append="/min" minimum="60" maximum="120">{{ vitalSigns.heartRate || '-' }}</v-label-value>
          </v-col>
          <v-col>
            <v-label-value label="RR" append="/min" minimum="16" maximum="24">{{ vitalSigns.respiratoryRate || '-' }}</v-label-value>
          </v-col>
          <v-col>
            <v-label-value label="BP" minimum="90" maximum="140">{{ vitalSigns.bloodPressureSystolic || '-' }}</v-label-value> /
            <v-label-value append="mmHg" minimum="60" maximum="90">{{ vitalSigns.bloodPressureDiastolic || '-' }}</v-label-value>
          </v-col>
          <v-col>
            <v-label-value label="O2 Sat" append="%" minimum="92">{{ vitalSigns.oxygenSaturation || '-' }}</v-label-value>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text v-else>
      <v-container py-0 fluid>
        <v-row>
          <v-col>
            <span class="headline">
              โปรดระบุรหัสบัตรประจำตัวประชาชน
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import api from 'tantee-common/lib/api'
import printDocument from '@/components/mixins/printDocument'

export default {
  mixins: [printDocument],
  data: ()=>({
    readyData: {},
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    isConsentReady: {
      type: [String, Boolean],
      default: 'no-consent'
    },
    showPrintPatientSlip: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    hn: {
      handler(newVal) {
        this.getReadiness(newVal)
      },
      immediate: true
    }
  },
  methods: {
    getReadiness(hn) {
      if (hn) {
        api.post(['Covid19','VaccineController','checkReadiness'],{hn: hn}).then((result)=>{
          if (result.success) {
            this.$emit('update:isConsentReady',result.data.consentCheck)
            this.readyData = result.data
          } else {
            this.$emit('update:isConsentReady',false)
          }
        }).catch((e)=>{
          void e
          this.$emit('update:isConsentReady',false)
        })
      } else {
        this.readyData = {}
      }
    },
    calculatedBMI(vitalSign) {
      if (vitalSign.height && vitalSign.weight) {
        return (vitalSign.weight/(Math.pow(vitalSign.height/100,2))).toFixed(2)
      } else {
        return null
      }
    },
    printCertificate() {
      if (this.readyData && this.readyData.mophVaccineCertificate && this.readyData.mophVaccineCertificateData) {
        this.printTemporary(this.hn,null,'cv19-vaccine-moph-certificate',this.readyData.mophVaccineCertificateData)
      }
    },
    printPatientSlip() {
      let printData = {}
      if (this.readyData) {
        printData['vaccineSuggestion'] = this.readyData.vaccineSuggestion
        printData['vaccineDose'] = this.readyData.vaccineHistoryCount + 1
      }
      this.printTemporary(this.hn,null,'patient-slip',printData)
    }
  },
  asyncComputed: {
    vitalSigns: {
      get() {
        return new Promise((resolve)=>{
          if (this.hn) {
            api.post(['Patient','PatientController','getVitalSigns'],{hn:this.hn}).then((result)=>{
              if (result.success) {
                  this.$emit('update:vitalSignsData',result.data)
                  resolve(result.data)
                } else {
                  this.$emit('update:vitalSignsData',undefined)
                  resolve(undefined)
                }
              }).catch((e)=>{
                void e
                this.$emit('update:vitalSignsData',undefined)
                resolve(undefined)
              })
          } else {
            this.$emit('update:vitalSignsData',undefined)
            resolve(undefined)
          }
        })
      },
      default: undefined
    }
  }
}
</script>

<style lang="css" scoped>
</style>
