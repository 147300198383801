import api from 'tantee-common/lib/api'
import Store from '@/store'
import Router from '@/router'
import helperClient from '@/modules/helperClient'
import * as helper from '@/modules/helperTracker'

export function stamp(hn,module=undefined) {
  let location = Store.getters.getConfig('location')
  let currentRoute = Router.currentRoute
  helperClient.getComputerName().then((computerName)=>{
    let trackData = {
      hn: hn,
      module: module,
      location: (location && location.locationCode) ? location.locationCode : undefined,
      clientId: computerName,
    }

    if (module) trackData['module'] = module
    else trackData['module'] = (currentRoute && currentRoute.name) ? currentRoute.name : "unspecified"

    api.post(['Patient','PatientController','trackerStamp'],trackData).then(()=>{}).catch((e)=>{void e})

  }).catch((e)=>{
    void e
  })
}

export default helper