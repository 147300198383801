const api = {
  default: {
    url: './api',
  },
  localDevice: {
    url: 'http://localhost:1234',
  },
}

const locale = 'th'

export default {
    api,
    locale
}
