export default {
  namePrefix: '$NamePrefix',
  nameSuffix: '$NameSuffix',
  maritalStatus: '$MaritalStatus',
  occupation: '$Occupation',
  religion: '$Religion',
  nationality: '$Nationality',
  addressSubdistrict: '$Subdistrict',
  addressDistrict: '$District',
  addressProvince: '$Province',
  addressCountry: '$Country',
  documentCategory: '$DocumentCategory',
  doctorType: '$DoctorType',
  doctorSpecialty: '$DoctorSpecialty',
  appointmentType: '$AppointmentType',
  appointmentActivity: '$AppointmentActivity',
}