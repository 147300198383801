export let moduleMenu = [
  {
    title: 'MasterData Configuration',
    action: 'library_books',
    menuItems: [
      {
        title: 'Clinics and locations',
        action: 'local_hospital',
        link: { name: 'master.clinicslocations' },
        permission: 'master-clinics-locations',
      },
      {
        title: 'Doctors',
        action: 'face',
        link: { name: 'master.doctors' },
        permission: 'master-doctors',
      },
      {
        title: 'Document Templates',
        action: 'description',
        link: { name: 'master.documents' },
        permission: 'master-document',
      },
      {
        title: 'Other MasterData',
        action: 'list_alt',
        link: { name: 'master.masterdata' },
        permission: 'master-others'
      },
    ],
    permission: 'master',
  },
]

export let moduleRoute = [
  {
    path: '/master/ClinicsLocations',
    name: 'master.clinicslocations',
    component: () => import('./ClinicsLocations')
  },
  {
    path: '/master/Doctors',
    name: 'master.doctors',
    component: () => import('./Doctors')
  },
  {
    path: '/master/Documents',
    name: 'master.documents',
    component: () => import('./Documents')
  },
  {
    path: '/master/MasterData',
    name: 'master.masterdata',
    component: () => import('./MasterData')
  },
]