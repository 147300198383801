export let moduleMenu = [
  {
    title: 'Registration',
    action: 'folder_shared',
    menuItems: [
      {
        title: 'Patient Information',
        action: 'person',
        link: { name: 'registration.patient' },
        permission: 'registration-patient-edit'
      },
      {
        title: 'Appointment',
        action: 'today',
        link: { name: 'registration.appointment' },
        permission: 'registration-patient-appointment',
      },
    ],
    permission: 'registration',
  },
]

export let moduleRoute = [
  {
    path: '/registration/Patient',
    name: 'registration.patient',
    component: () => import('./Patient')
  },
  {
    path: '/registration/Appointment',
    name: 'registration.appointment',
    component: () => import('./Appointment')
  },
]