<template lang="html">
  <v-model-table model-name="Insurances" model-title="Insurance Policies" model-key="insuranceCode"
    :model-api="['models','Master','Insurances']" :headers="headers" :initial-data="initialData">
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container pa-0 fluid>
        <v-row fluid>
          <v-col cols="12" md="2">
            <v-text-field :rules="[rules.require()]" v-model="data.insuranceCode" label="รหัสนโยบายประกัน/สิทธิ์" :disabled="!isCreating"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field :rules="[rules.require()]" v-model="data.insuranceName" label="ชื่อนโยบายประกัน/สิทธิ์"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-checkbox label="สำหรับผู้ป่วยนอก" v-model="data.isApplyToOpd"></v-checkbox>
          </v-col>
          <v-col cols="6" md="2">
            <v-checkbox label="สำหรับผู้ป่วยใน" v-model="data.isApplyToIpd"></v-checkbox>
          </v-col>
          <v-col cols="6" md="3">
            <v-checkbox label="ครอบคลุมทั้งหมด ยกเว้นระบุในเงื่อนไข" v-model="data.isCoverageAll"></v-checkbox>
          </v-col>
          <v-col cols="12" md="3">
            <v-radio-group v-model="data.priceLevel" label="ระดับราคา" row>
              <v-radio label="1" value="1"></v-radio>
              <v-radio label="2" value="2"></v-radio>
              <v-radio label="3" value="3"></v-radio>
              <v-radio label="4" value="4"></v-radio>
              <v-radio label="5" value="5"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field :rules="[rules.numeric()]" v-model="data.discount" label="ส่วนลด (%)"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-table-field v-model="data.conditions" title="เงื่อนไข" :headers="conditionsHeaders" :initial-data="{coverage: 'allow',conditionType: 'categoryInsurance'}" :dialog-width="($vuetify.breakpoint.mdAndUp) ? '60vw' : '80vw'" importable>
              <template v-slot:dataForm="{data : conData}">
                <v-container fluid>
                  <v-row>
                    <v-col cols="6" md="2">
                      <v-select
                        label="การครอบคุลม"
                        :rules="[rules.require()]"
                        :items="[
                          {text: 'ครอบคลุม', value: 'allow'},
                          {text: 'ไม่ครอบคลุม', value: 'deny'},
                        ]"
                        v-model="conData.coverage"
                        item-text="text"
                        item-value="value"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-select
                        label="ชนิดรายการ"
                        :rules="[rules.require()]"
                        :items="[
                          {text: 'หมวดหมู่ประกัน', value: 'categoryInsurance'},
                          {text: 'หมวดหมู่กรมบัญชีกลาง', value: 'categoryCgd'},
                          {text: 'หมวดหมู่สินค้า', value: 'category'},
                          {text: 'รายการสินค้า/บริการ', value: 'productCode'},
                        ]"
                        v-model="conData.conditionType"
                        item-text="text"
                        item-value="value"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="10" md="6" v-if="conData.conditionType == 'categoryInsurance'">
                      <v-master-field :rules="[rules.require()]" v-model="conData.conditionCode" label="หมวดหมู่ประกัน"
                        :groupKey="$store.getters.getGroupKey('productCategoryInsurance')"
                        clearable
                        show-code
                        key="categoryInsurance"
                      ></v-master-field>
                    </v-col>
                    <v-col cols="10" md="6" v-if="conData.conditionType == 'categoryCgd'">
                      <v-master-field :rules="[rules.require()]" v-model="conData.conditionCode" label="หมวดหมู่กรมบัญชีกลาง"
                        :groupKey="$store.getters.getGroupKey('productCategoryCgd')"
                        clearable
                        show-code
                        key="categoryCgd"
                      ></v-master-field>
                    </v-col>
                    <v-col cols="10" md="6" v-if="conData.conditionType == 'category'">
                      <v-master-field :rules="[rules.require()]" v-model="conData.conditionCode" label="หมวดหมู่สำหรับค้นหา"
                        :groupKey="$store.getters.getGroupKey('productCategory')"
                        clearable
                        show-code
                        key="category"
                      ></v-master-field>
                    </v-col>
                    <v-col cols="10" md="6" v-if="conData.conditionType == 'productCode'">
                      <v-master-field :rules="[rules.require()]" v-model="conData.conditionCode" 
                        :custom-api="['models','Master','Products','query']" custom-api-text="productName" 
                        custom-api-value="productCode" label="รายการสินค้า" 
                        show-code
                        key="productCode"
                      ></v-master-field>
                    </v-col>
                    <v-col cols="2" md="1">
                      <v-text-field :rules="[rules.require(),rules.numeric()]" label="Priority" v-model="conData.conditionPriority"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.conditionName="{item}">
                <v-label-api :api-url="['models','Master','Products','find']" :api-key="item.conditionCode" api-field="productName" v-if="item.conditionType == 'productCode'"></v-label-api>
                <v-label-master :group-key="'product'+upperFirst(item.conditionType)" :item-code="item.conditionCode" v-else></v-label-master>
              </template>
              <template v-slot:item.coverage="{item}">
                <v-icon>{{ (item.coverage=="allow") ? 'check' : 'close' }}</v-icon>
              </template>
              <template v-slot:item.action="props">
                <v-icon class="mx-1" @click.stop="props.actions.editData(props.item)">$vuetify.icons.edit</v-icon>
                <v-icon class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)">$vuetify.icons.delete</v-icon>
              </template>
            </v-table-field>
          </v-col>
          <v-col cols="12">
            <v-table-field v-model="data.coverPrices" title="ราคาที่ครอบคลุม" :headers="coverPricesHeaders" itemKey="productCode" :dialog-width="($vuetify.breakpoint.mdAndUp) ? '60vw' : '80vw'" importable>
              <template v-slot:dataForm="{data : conData}">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="10">
                      <v-master-field :rules="[rules.require()]" v-model="conData.productCode" 
                        :custom-api="['models','Master','Products','query']" custom-api-text="productName" 
                        custom-api-value="productCode" label="รายการสินค้า" 
                        show-code
                      ></v-master-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field :rules="[rules.require(),rules.numeric()]" label="ราคา" v-model="conData.coverPrice"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.productName="{item}">
                <v-label-api :api-url="['models','Master','Products','find']" :api-key="item.productCode" api-field="productName"></v-label-api>
              </template>
              <template v-slot:item.action="props">
                <v-icon class="mx-1" @click.stop="props.actions.editData(props.item)">$vuetify.icons.edit</v-icon>
                <v-icon class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)">$vuetify.icons.delete</v-icon>
              </template>
            </v-table-field>
          </v-col>
          <v-col cols="12">
            <v-textarea label="Note" v-model="data.note" auto-grow></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.isApplyToOpd="props">
      <v-icon class="text-center">{{ (props.item.isApplyToOpd) ? 'check' : 'close' }}</v-icon>
    </template>
    <template v-slot:item.isApplyToIpd="props">
      <v-icon class="text-center">{{ (props.item.isApplyToIpd) ? 'check' : 'close' }}</v-icon>
    </template>
  </v-model-table>
</template>

<script>
import {upperFirst} from 'lodash'
export default {
  data: ()=>({
    headers: [
      {
        text: 'รหัส',
        value: 'insuranceCode',
        class: 'body-2'
      },
      {
        text: 'ชื่อประกัน/สิทธิ์',
        value: 'insuranceName',
        class: 'body-2'
      },
      {
        text: 'สำหรับผู้ป่วยนอก',
        value: 'isApplyToOpd',
        class: 'body-2'
      },
      {
        text: 'สำหรับผู้ป่วยใน',
        value: 'isApplyToIpd',
        class: 'body-2'
      },
      {
        text: 'หมายเหตุ',
        value: 'note',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    conditionsHeaders: [
      {
        text: 'ชนิด',
        value: 'conditionType'
      },
      {
        text: 'รหัส',
        value: 'conditionCode',
        class: 'body-2',
        width: '150px',
      },
      {
        text: 'รายการ',
        value: 'conditionName',
        class: 'body-2'
      },
      {
        text: 'การครอบคลุม',
        value: 'coverage',
        class: 'body-2'
      },
      {
        text: 'Priority',
        value: 'conditionPriority',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    coverPricesHeaders: [
      {
        text: 'รหัสสินค้า',
        value: 'productCode',
        class: 'body-2',
        width: '150px',
      },
      {
        text: 'รายการ',
        value: 'productName',
        class: 'body-2'
      },
      {
        text: 'ราคา',
        value: 'coverPrice',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    initialData: {
      priceLevel: '1',
      isCoverageAll: true,
      isApplyToOpd: true,
      isApplyToIpd: true,
    }
  }),
  methods: {
    upperFirst(str) {
      return upperFirst(str)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
