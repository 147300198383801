import api from 'tantee-common/lib/api'
import Store from '@/store'
import { isArray } from 'lodash'

function removePrefix(string, prefixs) {
  if (!isArray(prefixs)) {
    prefixs = [prefixs]
  }
  prefixs.forEach((prefix)=>{
    if (string.startsWith(prefix)) string = string.slice(prefix.length)
  })
  return string
}

export default async function() {
  let apiUrl = ['localDevice','IDCard','read']
  let returnData = {
    patient: {
      personIdDetail: {},
    },
    name: [{},{}],
    address: [{}],
  }

  await api.get(apiUrl).then(async (cardData) => {
    if (cardData.success) {

      if (cardData.data.ThaiTitleName == 'น.ส.') cardData.data.ThaiTitleName = 'นางสาว'

      returnData.patient['personId'] = cardData.data.NationalID
      returnData.patient['personIdType'] = 1
      returnData.patient['personIdVerified'] = true
      returnData.patient['personIdDetail'] = cardData.data

      returnData.name[0]['namePrefix'] = await Store.getters.getItemCode(Store.getters.getGroupKey('namePrefix'),cardData.data.ThaiTitleName)
      returnData.name[0]['nameType'] = 'TH'
      returnData.name[0]['firstName'] = cardData.data.ThaiFirstName
      returnData.name[0]['middleName'] = cardData.data.ThaiMiddleName
      returnData.name[0]['lastName'] = cardData.data.ThaiLastName

      returnData.name[1]['namePrefix'] = await Store.getters.getItemCode(Store.getters.getGroupKey('namePrefix'),cardData.data.EnglishTitleName,undefined,true)
      returnData.name[1]['nameType'] = 'EN'
      returnData.name[1]['firstName'] = cardData.data.EnglishFirstName
      returnData.name[1]['middleName'] = cardData.data.EnglishMiddleName
      returnData.name[1]['lastName'] = cardData.data.EnglishLastName

      if (!returnData.name[0]['namePrefix'] && returnData.name[1]['namePrefix']) returnData.name[0]['namePrefix'] = returnData.name[1]['namePrefix'];
      if (!returnData.name[1]['namePrefix'] && returnData.name[0]['namePrefix']) returnData.name[1]['namePrefix'] = returnData.name[0]['namePrefix'];
      if (returnData.name[0]['namePrefix'] != returnData.name[1]['namePrefix']) returnData.name[1]['namePrefix'] = returnData.name[0]['namePrefix'];

      returnData.patient['dateOfBirth'] = cardData.data.Birthdate
      returnData.patient['sex'] = cardData.data.Sex

      returnData.photoIDCard = 'data:image/png;base64,'+cardData.data.Base64Photo

      returnData.address[0]['addressType'] = 'census'
      returnData.address[0]['address'] = cardData.data.Address
      returnData.address[0]['moo'] = cardData.data.Moo
      returnData.address[0]['trok'] = cardData.data.Trok
      returnData.address[0]['soi'] = cardData.data.Soi
      returnData.address[0]['street'] = cardData.data.Thanon
      returnData.address[0]['subdistrict'] = cardData.data.Thanon

      returnData.address[0]['province'] = await Store.getters.getItemCode(Store.getters.getGroupKey('addressProvince'),removePrefix(cardData.data.Province,'จังหวัด'))
      returnData.address[0]['district'] = await Store.getters.getItemCode(Store.getters.getGroupKey('addressDistrict'),removePrefix(cardData.data.Amphur,['เขต','อำเภอ']),returnData.address[0]['province'] )
      returnData.address[0]['subdistrict'] = await Store.getters.getItemCode(Store.getters.getGroupKey('addressSubdistrict'),removePrefix(cardData.data.Tumbol,['แขวง','ตำบล']),returnData.address[0]['district'] )

    } else {
      if (cardData.errorTexts.length>0) {
        cardData.errorTexts.forEach((error) => {
          Store.dispatch('addAlertItem',{
            alertLocation: 'top',
            alertType: 'error',
            alertText: error
          })
        })
      }
    }
  }).catch((e) => void e)

  return returnData
}
