<template lang="html">
  <v-form-pad ref="addressForm" v-model="addressData" :disabled="disabled">
    <template v-slot="{data, rules}">
      <v-container pa-0 fluid>
        <v-row dense>
          <v-col cols="12" :md="(foreignAddress) ? '6' : '3'">
            <v-text-field v-model="data.address" :label="(foreignAddress) ? 'Address' : 'บ้านเลขที่'"
              :rules="[rules.requireIf(required && !disabled)]"
              :disabled="disabled"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="!foreignAddress">
            <v-text-field v-model="data.village" label="คอนโด/หมู่บ้าน"
              :disabled="disabled"></v-text-field>
          </v-col>
          <v-col cols="4" md="1" v-if="!foreignAddress">
            <v-text-field v-model="data.moo" label="หมู่ที่"
              :disabled="disabled"></v-text-field>
          </v-col>
          <v-col cols="4" md="1" v-if="!foreignAddress">
            <v-text-field v-model="data.trok" label="ตรอก"
              :disabled="disabled"></v-text-field>
          </v-col>
          <v-col cols="4" md="1" v-if="!foreignAddress">
            <v-text-field v-model="data.soi" label="ซอย"
              :disabled="disabled"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="data.street" :label="(foreignAddress) ? 'Street' : 'ถนน'"
              :disabled="disabled"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="!foreignAddress">
            <v-master-field v-model="data.subdistrict" label="ตำบล/แขวง"
              :groupKey="$store.getters.getGroupKey('addressSubdistrict')" :filterText="data.district"
              wait-for-filter wait-for-filter-text="กรุณาเลือก อำเภอ/เขต"
              :rules="[rules.requireIf(required && !disabled)]"
              :disabled="disabled" key="subdistrict"></v-master-field>
          </v-col>
          <v-col cols="12" md="3" v-else>
            <v-text-field v-model="data.subdistrict" label="Suburb/Area"
              :disabled="disabled"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="!foreignAddress">
            <v-master-field v-model="data.district" label="อำเภอ/เขต"
              :groupKey="$store.getters.getGroupKey('addressDistrict')" :filterText="data.province"
              wait-for-filter wait-for-filter-text="กรุณาเลือก จังหวัด"
              :rules="[rules.requireIf(required && !disabled)]"
              :disabled="disabled" @change="onDistrictChange" key="district"></v-master-field >
          </v-col>
          <v-col cols="12" md="3" v-else>
            <v-text-field v-model="data.district" label="City"
              :rules="[rules.requireIf(required && !disabled)]"
              :disabled="disabled"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="foreignAddress">
            <v-text-field v-model="data.province" label="State/Province/Region"
              :rules="[rules.requireIf(required && !disabled)]"
              :disabled="disabled"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-else>
            <v-master-field v-model="data.province" :groupKey="$store.getters.getGroupKey('addressProvince')" label="จังหวัด"
              :rules="[rules.requireIf(required && !disabled)]"
              :disabled="disabled" @change="onProvinceChange" key="province"></v-master-field>
          </v-col>
          <v-col cols="12" md="3" v-if="foreignAddress">
            <v-master-field v-model="data.country" :groupKey="$store.getters.getGroupKey('addressCountry')" label="Country"
              :rules="[rules.requireIf(required && !disabled)]"
              :disabled="disabled" english key="country"></v-master-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="data.postCode" :label="(foreignAddress) ? 'Postcode' : 'รหัสไปรษณีย์'"
              :disabled="disabled"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-form-pad>
</template>

<script>
import { isUndefined } from 'lodash'

export default {
  data: ()=>({
    addressData: {
    },
  }),
  props: {
    addressType: {
      type: String,
      default: undefined
    },
    foreignAddress: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => ({})
    },
    english: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler() {
        this.loadAddress()
      },
      deep: true
    },
    addressData: {
      handler(newVal) {
        this.$emit("input",newVal)
      },
      deep: true
    },
    foreignAddress: function() {
      this.loadAddress()
    }
  },
  methods: {
    loadAddress() {
      let tempAddress = this.value
      if (!this.foreignAddress) {
        tempAddress.country = 'TH'
      } else {
        if (tempAddress.country == 'TH') delete tempAddress.country
        delete tempAddress.village
        delete tempAddress.moo
        delete tempAddress.trok
        delete tempAddress.soi
      }
      if (!isUndefined(this.addressType)) {
        tempAddress.addressType = this.addressType
      }
      this.addressData = tempAddress
    },
    onProvinceChange() {
      this.$set(this.addressData,'district',undefined)
      this.$set(this.addressData,'subdistrict',undefined)
      this.$forceUpdate()
    },
    onDistrictChange() {
      this.$set(this.addressData,'subdistrict',undefined)
      this.$forceUpdate()
    }
  },
  mounted() {
    this.loadAddress()
  },
}
</script>

<style lang="css">
</style>
