import Vue from 'vue'
import Router from 'vue-router'
import { unionBy } from 'lodash'

Vue.use(Router)

const requireRoute = require.context(
  './views',
  true,
  /module.js$/
)

let defaultRoute = [
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
  // },
]

requireRoute.keys().forEach(fileName => {
  const module = requireRoute(fileName)
  if (module.moduleRoute) defaultRoute = unionBy(defaultRoute, module.moduleRoute, 'name')
})

export default new Router({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes: defaultRoute
})