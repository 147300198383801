<template lang="html">
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Encounter Registration</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-model-table model-name="Appointments" model-title="รายการนัดหมายวันนี้"
        :model-api="['models','Appointment','Appointments']"
        :model-api-search="appointmentQuery"
        :insertable="false"
        :headers="appointHeaders" v-if="hn"
        ref="appointment"
      >
        <template v-slot:item.appointmentDateTime="props">
          <v-label-datetime :date-time="props.item.appointmentDateTime" short-date-time></v-label-datetime>
        </template>
        <template v-slot:item.appointmentType="props">
          <v-label-master group-key="appointmentType" :item-code="props.item.appointmentType"></v-label-master>
        </template>
        <template v-slot:item.appointmentActivity="props">
          <v-label-master group-key="appointmentActivity" :item-code="props.item.appointmentActivity"></v-label-master>
        </template>
        <template v-slot:item.action="props">
          <v-btn @click="encounterFromAppointment(props.item)" v-if="!props.item.to_encounter || props.item.to_encounter.length==0">Open Encounter</v-btn>
          <v-icon color="green darken-3" v-else>mdi-check-circle-outline</v-icon>
        </template>
      </v-model-table>
    </v-card-text>
    <v-card-text>
      <v-model-table model-name="Encounters" model-title="รายการลงทะเบียนวันนี้"
        :model-api="['models','Registration','Encounters']" model-key="encounterId"
        :model-api-search="encounterQuery"
        api-with="transactions"
        :initial-data="{'hn': hn, 'encounterType': 'AMB'}"
        :headers="headers" v-if="hn"
        :dialog-width="($vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw'"
        @dataCreated="reloadAppointment" @dataDeleted="reloadAppointment"
        ref="encounter"
        multiple-select
      >
        <template v-slot:modelForm="{data,rules,isCreating}">
          <v-container pa-0 fluid>
            <v-row>
              <v-col cols="12" md="4">
                <v-master-field v-model="data.clinicCode" :custom-api="['models','Master','Clinics','query']"
                  custom-api-text="clinicName" custom-api-value="clinicCode"
                  :filter-text="{encounterType: 'AMB'}"
                  :value-object.sync="currentClinic"
                  customApiEagerLoad
                  :rules="[rules.require()]"
                  label="คลินิก" show-code></v-master-field>
                <v-hidden-field v-model="data.locationCode" :item-value="(currentClinic) ? currentClinic.locationCode : undefined"></v-hidden-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select :label="(currentClinic && currentClinic.location) ? currentClinic.location.locationName : 'ห้องตรวจ'"
                  :items="(currentClinic && currentClinic.location) ? currentClinic.location.subunits : undefined"
                  v-model="data.locationSubunitCode"
                  item-text="subunitName"
                  item-value="subunitCode"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-master-field v-model="data.doctorCode" :custom-api="['models','Master','Doctors','query']"
                  custom-api-text="nameTH" custom-api-value="doctorCode"
                  :filter-text="(currentClinic && currentClinic.specialty) ? {specialty: currentClinic.specialty} : undefined"
                  customApiEagerLoad
                  :rules="[rules.require()]"
                  label="แพทย์" show-code></v-master-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-date-time-field v-model="data.admitDateTime" :rules="[rules.require()]" label="วัน-เวลาตรวจ" :defaultDate="true" fixedDate></v-date-time-field>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:item.encounterId="props">
          <v-icon v-if="props.item.fromAppointmentId" color="green darken-3">mdi-calendar-check</v-icon>
          <v-icon v-else color="red">mdi-calendar-remove</v-icon>
          <label class="font-weight-bold">{{ props.item.encounterId.slice(-3) }}</label>
        </template>
        <template v-slot:item.admitDateTime="props">
          <v-label-datetime :date-time="props.item.admitDateTime" short-date-time></v-label-datetime>
        </template>
        <template v-slot:item.locationSubunitCode="props">
          <v-label-items :items="props.item.location.subunits" item-key="subunitCode" item-value="subunitName" :value="props.item.locationSubunitCode"></v-label-items>
        </template>
        <template v-slot:item.action="props">
          <v-dialog v-model="printDocDialog" max-width="80vw" fullscreen>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-file-document-box-outline</v-icon>
            </template>
            <v-card>
              <v-card-title primary-title class="headline">
                <slot name="title">พิมพ์เอกสาร</slot>
              </v-card-title>
              <v-card-text>
                <v-document-print-list :hn="props.item.hn" :encounterId="props.item.encounterId" :show-ipd="false"></v-document-print-list>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary lighten-1" @click="printDocDialog=false">ปิด</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-icon class="mx-1" @click.stop="props.actions.editData(props.item)">$vuetify.icons.edit</v-icon>
          <v-icon class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)" v-if="!props.item.transactions || props.item.transactions.length==0">$vuetify.icons.delete</v-icon>
        </template>
        <template v-slot:body.append="props">
          <td><v-icon>mdi-subdirectory-arrow-right</v-icon></td>
          <td :colspan="props.headers.length">
            <v-btn @click="printEncounterSlip(props.selectedItems)" :disabled="!props.selectedItems || !props.selectedItems.length">พิมพ์ใบนำทาง</v-btn>
          </td>
        </template>
      </v-model-table>
    </v-card-text>
  </v-card>
</template>

<script>
import helperDateTime from 'tantee-common/lib/helperDateTime'
import printDocument from '@/components/mixins/printDocument'

export default {
  mixins: [printDocument],
  data: ()=>({
    currentClinic: undefined,
    printDocDialog: false,
    headers: [
      {
        text: 'เลขรับบริการ',
        value: 'encounterId',
        class: 'body-2'
      },
      {
        text: 'เวลาเข้าตรวจ',
        value: 'admitDateTime',
        class: 'body-2'
      },
      {
        text: 'สถานที่',
        value: 'location.locationName',
        class: 'body-2',
        width: '200px'
      },
      {
        text: 'คลินิก',
        value: 'clinic.clinicName',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'ห้องตรวจ',
        value: 'locationSubunitCode',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '200px',
        sortable: false,
        class: 'body-2'
      }
    ],
    appointHeaders: [
      {
        text: 'เวลานัดหมาย',
        value: 'appointmentDateTime',
        class: 'body-2',
      },
      {
        text: 'ระยะเวลา (นาที)',
        value: 'appointmentDuration',
        class: 'body-2',
      },
      {
        text: 'คลินิก',
        value: 'clinic.clinicName',
        class: 'body-2',
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'ชนิดการนัด',
        value: 'appointmentType',
        class: 'body-2'
      },
      {
        text: 'กิจกรรม',
        value: 'appointmentActivity',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
  computed: {
    encounterQuery() {
      let result = {
        hn: this.hn,
        encounterType: 'AMB',
        'whereDate#admitDateTime': helperDateTime.today().format('YYYY-MM-DD')
      }
      return result
    },
    appointmentQuery() {
      return {
        hn: this.hn,
        'whereDate#appointmentDateTime': helperDateTime.today().format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    encounterFromAppointment(item) {
      this.$refs.encounter.newData({
        clinicCode: item.clinicCode,
        doctorCode: item.doctorCode,
        admitDateTime: item.appointmentDateTime,
        fromAppointmentId: item.id
      })
    },
    reloadAppointment() {
      this.$refs.appointment.loadData()
    },
    printEncounterSlip(encounterData) {
      let slipData = {
        encounters : encounterData
      }
      this.printTemporary(this.hn,undefined,'encounter-slip-opd',slipData);
    }
  }
}
</script>

<style lang="css" scoped>
</style>
